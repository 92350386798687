import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from "@angular/router";
import { AppHelpers } from '../helpers/app.helpers';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService,
		private router: Router,
		public appHelpers: AppHelpers
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(err => {
			if (err.status === 401) {
				// auto logout if 401 response returned from api
				this.appHelpers.setUnauthorizationStatus(true);
				this.authService.logout();
				this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url } });
			}
			if (err.status === 403) {
				this.router.navigate(['/access-denied']);
			}
			return throwError(err);
		}));
	}
}
