import { AbstractControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';

export class CustomValidators {

	static getAddressValidators(): any {
		return Validators.compose([
			Validators.required,
			Validators.minLength(30),
			Validators.maxLength(150)
		]);
	}

	static getNameValidators(): any {
		return Validators.compose([
			Validators.required,
			Validators.minLength(2),
			Validators.maxLength(50)
		]);
	}

	static getEmailValidators(): any {
		return Validators.compose([
			Validators.required,
			Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
			Validators.maxLength(64)
		]);
	}

	static getPasswordValidators(): any {
		return Validators.compose([
			Validators.required,
			// Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*().])\S{8,}$/)
			Validators.pattern(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*().])\S{8,}$/)
		]);
	}

	static matchPassword(AC: AbstractControl): any {
		const password = AC.get('new_password').value; // to get value in input tag
		const confirmPassword = AC.get('confirm_password').value; // to get value in input tag
		if (password !== confirmPassword) {
			AC.get('confirm_password').setErrors({ matchpassword: true });
		} else {
			return null;
		}
	}

	static matchPasswordRegister(AC: AbstractControl): any {
		const password = AC.get('password').value; // to get value in input tag
		const confirmPassword = AC.get('confirm_password').value; // to get value in input tag
		if (password !== confirmPassword) {
			AC.get('confirm_password').setErrors({ matchpassword: true });
		} else {
			return null;
		}
	}

	static getPhoneValidators(): any {
		return Validators.compose([
			Validators.required,
			// Validators.pattern(/^[0-9]*\S{10,}$/),
			// Validators.maxLength(15) // Included country code
		]);
	}

	static getCardNumberValidators(): any {
		return Validators.compose([
			Validators.required,
			Validators.pattern(/^[0-9]*\S{13,}$/)
		]);
	}

	static getCVVValidators(): any {
		return Validators.compose([
			Validators.required,
			Validators.pattern(/^[0-9]*\S{3,3}$/)
		]);
	}

	static validCVV(AC: AbstractControl): any {
		const currentCardNumber = AC.get('card_number').value.toString().replace(/[ ]/g, '');
		const currentCardCVV = AC.get('card_cvv').value;
		if (!currentCardCVV.match(/^[0-9]*\S{3,3}$/)
			|| (currentCardNumber.match(/^3[47][0-9]{13}$/) && !currentCardCVV.match(/^[0-9]*\S{4,4}$/))) {
			AC.get('card_cvv').setErrors({ invalid_cvv: true });
		} else {
			return null;
		}
	}

	static checkLimit(min: number, max: number) {
		return (c: AbstractControl): { [key: string]: boolean } | null => {
			if ((c.value || c.value === 0) && (isNaN(c.value) || c.value < min || c.value > max)) {
				return { 'range': true };
			}
			return null;
		};
	}

	static noWhiteSpace(control: AbstractControl) {
	    const isWhitespace = (control.value || '').toString().trim().length === 0;
	    const isValid = !isWhitespace;
	    return isValid ? null : { 'whitespace': true };
	}

    static validAddress(lat: number, lng: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (!lat || !lng) {
            return { 'invalidAddress': true };
        }
        return null;
    };
}
}
