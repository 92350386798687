export const environment = {
    production: false,
    ssl: true,
    name: 'QC',
    baseUrl: 'https://gw.dms.demo.meksmart.com',
    homepageUrl: 'https://dms.demo.meksmart.com/',
    prefixApi: {
        auth: '/auth/v1',
        beApi: '/backend-api/v1',
        masterData: '/masterdata/v1'
    },
    ggAppKey: 'AIzaSyA1wCmTA6ipuz_dLECMab3vHdLMJsUmHFs',
    baseGPSUrl: 'https://dms.demo.meksmart.com',
    baseSocketUrl: 'https://dms.demo.meksmart.com/login',
    defaultCountryISOCode: 'IL',
    defaultAppTimezone: 'Israel',
    defaultAppLocation: {
        lat: 32.0902013,
        lng: 34.813012
    },
    baseRouteXLUrl: '',
    usernameRouteXL: '',
    passwordRouteXL: '',
    path_lang: 'https://dms.demo.meksmart.com/assets/i18n/',
    linkApk: 'https://dms.demo.meksmart.com/assets/app/thanhdat-1.0.15.apk',
    linkIos: 'https://testflight.apple.com/join/0FdiOFXP',
    lang: [{ code: 'en', name: 'English' }, { code: 'vi', name: 'Tiếng Việt' }],
    default_avatar: './assets/images/default-user.png',
    url_avatar: 'https://gw.dms.demo.meksmart.com/auth/images/'
};
