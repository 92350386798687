import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'avatar-preview-modal',
	templateUrl: 'avatar-preview.modal.html'
})

export class AvatarPreviewModal implements OnInit {

	modalTitle = 'Avatar Preview'
	public onClose: Subject<Object>;
	avatarPreviewPath: any;

	constructor(
		public bsModalRef: BsModalRef
	) { }

	ngOnInit() {
		this.onClose = new Subject();
	}

	setNewAvatar() {
		this.onClose.next(this.avatarPreviewPath);
	}

}
