import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpBackend, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { DragulaModule } from 'ng2-dragula';
import { ClipboardModule } from 'ngx-clipboard';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/guards/auth.guard';
import { HttpService, SearchService } from './core/services';
import { AppHelpers } from './core/helpers';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { LoginComponent } from './public/login/login.component';
import { RegisterComponent } from './public/register/register.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { SetupPasswordComponent } from './public/setup-password/setup-password.component';
import { AccessDeniedComponent } from './public/access-denied/access-denied.component';
import { PageNotFoundComponent } from './public/page-not-found/page-not-found.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { TranslationService } from './core/services/translationService';

// export const options: Partial<IConfig> | (() => Partial<IConfig>);
// export const createTranslateLoader = (http: HttpClient) => {
// 	return new TranslateHttpLoader(http, environment.path_lang , '.json');
// };

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		SetupPasswordComponent,
		AccessDeniedComponent,
		PageNotFoundComponent
	],
	imports: [
		CoreModule,
		SharedModule.forRoot(),
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgxMaskModule.forRoot(),
		ClipboardModule,
		ToastrModule.forRoot({
			preventDuplicates: true
		}),
		DragulaModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: TranslationService,
				// useFactory: (createTranslateLoader),
				deps: [HttpBackend]
			}
		}),
	],
	providers: [
		AuthGuard,
		AppHelpers,
		HttpService,
		SearchService,
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
		// { provide: LOCALE_ID, useValue: 'he' }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
