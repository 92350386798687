import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'salary'
})
export class SalaryPipe implements PipeTransform {
    transform(val) {
        if (val.includes(",")) {
            return val;
        } else {
            return parseInt(val).toLocaleString('en-US', { maximumFractionDigits: 0 });
        }
    }
}
