import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { AppUtils } from '../../../core/helpers/index';
import { DropzoneDirective } from 'ngx-dropzone-wrapper';
declare const $: any;

@Component({
	selector: 'app-file-dropzone',
	templateUrl: './file-dropzone.component.html',
	styleUrls: ['./file-dropzone.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FileDropzoneComponent implements OnInit {
	@Input() config: any;
	@Input() style: any;
	@Input() maxFiles: any;
	@Input() editable: boolean;
	@Input() uploadedFiles: any;

	@ViewChild("fileDropZone") fileDropZone: ElementRef;
	fileDropZoneInstance: any;
	defaultConfig = {
		url: 'url',
		autoProcessQueue: false,
		acceptedFiles: 'image/*',
		thumbnailMethod: 'contain'
	}
	defaultStyle = {
		fileDropzoneCtnHeight: '10rem',
		dropZoneBorder: '1px dashed #395aa8',
		previewHeight: '8rem',
		previewWidth: '8rem',
		previewMargin: '1rem'
	}
	isMaxFilesExceeded: boolean = false;

	constructor() {
		this.config = !this.config ? this.defaultConfig : this.config;
		this.style = !this.style ? this.defaultStyle : this.style;
		this.editable = (this.editable === null || typeof this.editable === 'undefined')
			? true : this.editable;
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.fileDropZoneInstance = this.fileDropZone.nativeElement.dropzone;
		this.fileDropZoneInstance.options.previewTemplate = $('#previewTemplate').html();
		this.fileDropZoneInstance.options.maxFiles = this.maxFiles ? parseInt(this.maxFiles) : null;

		if (this.uploadedFiles && this.uploadedFiles instanceof Array && this.uploadedFiles.length > 0) {
			this.uploadedFiles.forEach(file => {
				const mockFile = {
					name: file.name,
					type: file.type,
					accepted: true,
					dataURL: file.url
				};

				this.fileDropZoneInstance.emit('addedfile', mockFile);
				this.fileDropZoneInstance.options.maxFiles--;
				this.fileDropZoneInstance.createThumbnailFromUrl(mockFile, 120, null, 'contain', true, (thumbnail) => {
					this.fileDropZoneInstance.files.push(thumbnail);
					this.fileDropZoneInstance.emit('thumbnail', mockFile, thumbnail);
				},
					'Anonymous'
				);
				this.fileDropZoneInstance.emit('complete', mockFile);
			});
		}
	}

	onMaxFilesExceeded(event) {
		if (this.maxFiles && event) {
			this.isMaxFilesExceeded = true;
		} else {
			this.isMaxFilesExceeded = false;
		}
		this.fileDropZoneInstance.removeFile(event);
	}

	onError(event) {
		if (event && event instanceof Array && event.length > 0) {
			if (!event[0].accepted || event[0].status === 'error') {
				this.fileDropZoneInstance.removeFile(event[0]);
			}
		}
	}

}
