import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { AppHelpers, AppUtils } from 'src/app/core/helpers';
import { LocationsService } from 'src/app/feature/admin/locations/locations.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs/Subject';

@Component({
    selector: 'location-create-modal',
    templateUrl: 'location-create.modal.html'
})

export class LocationCreateModalComponent implements OnInit, OnDestroy,  AfterViewInit {

    onClose: Subject<Object>;
    form: FormGroup;
    submitted = false;
    selectedCountry: any;
    listCountries: any;
    objectKeys = Object.keys;
    defaultLocation = AppUtils.getDefaultAppLocation();
    listCustomer: any = [];
    locationTypes = [];
    listRampManager: any = [];
    addressAutoComplete: any;
    place: google.maps.places.PlaceResult;
    @ViewChild('addressSuggestion')
    addressInput: ElementRef;
    mapLocation: any;
    title = 'Create Location';
    elOrderModal = null;
    elLocationModal = null;

    cd: string;
    customerId: number;
    locId: any;

    defaultAddress = '';
    hasWarehouse: boolean = false;

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: FormBuilder,
        public appHelpers: AppHelpers,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private locationsService: LocationsService,
        private translate: TranslateService,

    ) {
    }

    ngOnInit() {
        this.elOrderModal = <any>document.getElementsByClassName('order-details')[0];
        this.elLocationModal = <any>document.getElementsByClassName('location-detail')[0];
        this.onClose = new Subject();
        this.getLocationTypeList();
        this.getRampManagerList();
        this.getCustomerList();
        this.buildForm();

        this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            this.initAddressAutocomplete();
        });
        this.appHelpers.getCountriesMasterdata().subscribe((result) => {
            this.listCountries = result;
            this.selectedCountry = this.appHelpers.getCountryInfoByISOCode(this.listCountries);
        });
        if (this.locId) {
            this.title = 'Edit Location';
            this.getLocationDetails(this.locId);
            // this.disableFormControl(); // as informed from leader, let user freely updates anything.
        }
    }

    ngOnDestroy() {
        if (this.elOrderModal) {
            this.elOrderModal.removeAttribute('style');
        }
        if (this.elLocationModal) {
            this.elLocationModal.removeAttribute('style');
        }
    }

    ngAfterViewInit() {
        if (this.elOrderModal) {
            // this.elOrderModal.style.opacity = 0.1;
        }
        setTimeout(() => {
            this.elLocationModal = <any>document.getElementsByClassName('location-detail')[0];
            if (this.elLocationModal) {
                let temp = this.elLocationModal.parentNode;
                temp.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
            }
        }, 100)

        this.drawMap();
    }

    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.defaultLocation.lat = position.coords.latitude;
                this.defaultLocation.lng = position.coords.longitude;
            });
        }
    }

    buildForm() {
        this.form = this.formBuilder.group({
            ctt_name: [null, Validators.required],
            phone: [null],
            email: [null],
            address: [null, Validators.compose([Validators.required])],
            city: [null],
            state: [null],
            zip: [null],
            latitude: [null],
            longitude: [null],
            customer_id: [this.customerId],
            active: [1],
            type_ids: [[]],
            mgr_ids: [[]],
			name: [null],
            floor: [null],
			apt: [null],
			number: [null]
        });
    }

    onFormValueChanged() {
		this.form.get('type_ids').valueChanges.subscribe(val => {
			const warehouse = this.locationTypes.find(location => { return location.cd === 'WH' });
			const warehouseId = warehouse.id;
			this.hasWarehouse = val.indexOf(warehouseId) > -1;
			if (!this.hasWarehouse) {
				this.form.controls['mgr_ids'].setValue([]);
			}
		});
	}

    getLocationDetails(id) {
		this.locationsService.getLocationDetails(id).subscribe(result => {
			const data = result['data'];
			if (data.type_ids) {
				data.type_ids = data.type_ids.map((o) => {
					return o.id.toString();
				});
			}
			if (data.managers) {
				data.mgr_ids = data.managers.map((o) => {
					return o.id.toString();
				});
			}
			this.defaultAddress = data.address;

            setTimeout(() => {
                this.form.patchValue(data); // wait Ramp List
            }, 300)

			this.mapsAPILoader.load().then(() => {
				this.setCurrentLocation();
				this.initAddressAutocomplete();
			});
			this.drawMap(data.latitude, data.longitude, data.address);

			this.appHelpers.getCountriesMasterdata().subscribe((result) => {
				this.listCountries = result;
				if (data.phone) {
					const countryISOCode = this.appHelpers.getCountryISOCodeFromPhone(this.listCountries, data.phone);
					this.changeCountry(countryISOCode);
					const phoneNumber = data.phone.replace(this.selectedCountry.countryCode, '');
					this.form.controls['phone'].setValue(phoneNumber);
				} else {
					this.selectedCountry = this.appHelpers.getCountryInfoByISOCode(this.listCountries);
				}
			});
		});
	}

    getCustomerList() {
        this.locationsService.getCustomerList('BCO').subscribe(result => {
            // @ts-ignore
            this.listCustomer = result.data;
        }, err => {
            console.log(err);
        });
    }

    getLocationTypeList() {
        this.locationsService.getLocationTypeList().subscribe(result => {
            // @ts-ignore
            this.locationTypes = result.data || [];
            this.locationTypes.forEach((locationType) => {
                if (this.cd === locationType.cd) {
                    this.form.controls['type_ids'].setValue(locationType.id);
                }
            });
            this.onFormValueChanged();
        }, err => {
            console.log(err);
        });
    }

    getRampManagerList() {
		this.locationsService.getRampManagerList('RM').subscribe(result => {
			// @ts-ignore
			this.listRampManager = result.data;
		}, err => {
			console.log(err);
		});
    }

    changeCountry(countryISOCode) {
        this.selectedCountry = this.appHelpers.getCountryInfoByISOCode(this.listCountries, countryISOCode);
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            // this.formatPhoneNumber();
            this.submitted = true;
            const params = this.form.getRawValue();
            params['update_order'] = 1;

            if (this.locId) {
                this.locationsService.editLocation(this.locId, params).subscribe(res => {
                    this.submitted = false;
                    this.appHelpers.showToast('success', this.translate.instant('Updated location successfully'));
                    this.onClose.next(res['data']);
                }, err => {
                    this.submitted = false;
                    this.appHelpers.showToast('error', err.message);
                });
            } else {
                this.locationsService.createLocation(params).subscribe(res => {
                    this.submitted = false;
                    this.appHelpers.showToast('success', this.translate.instant('Created location successfully'));
                    this.onClose.next(res['data']);
                }, err => {
                    this.submitted = false;
                    this.appHelpers.showToast('error', err.message);
                });
            }
        }
    }

    formatPhoneNumber() {
        // Reset the format first
        const phoneNumber = this.form.value['phone'] ? parseInt(this.form.value['phone'].replace(this.selectedCountry.countryCode, '')) : '';
        if (phoneNumber) {
            this.form.controls['phone'].setValue(this.selectedCountry.countryCode + phoneNumber);
        }
    }

    initAddressAutocomplete() {
        const t = this;
        this.addressAutoComplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, { types: ['address'] });
        this.addressAutoComplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                this.place = this.addressAutoComplete.getPlace();
                if (this.place.geometry === undefined || this.place.geometry === null) {
                    return;
                } else {
                    const address = t.place.formatted_address;
                    let state = '', zipCode = '', city = '';

                    if (t.place.address_components) {
                        for (let i = 0; i < t.place.address_components.length; i++) {

                            const addressType = t.place.address_components[i].types[0];

                            if (addressType === 'locality') {
                                city = t.place.address_components[i].long_name;
                            }

                            if (addressType === 'administrative_area_level_1') {
                                state = t.place.address_components[i].short_name;
                            }

                            if (addressType === 'postal_code') {
                                zipCode = t.place.address_components[i].short_name;
                            }

                            if (addressType === 'country' && t.place.address_components[i].short_name === 'VN') {
                                zipCode = '70000'; city = state;
                            }
                        }
                    }
                    const location = this.place.geometry.location;
                    (<FormControl>this.form.controls['address']).patchValue(address);
                    (<FormControl>this.form.controls['city']).patchValue(city);
                    (<FormControl>this.form.controls['state']).patchValue(state);
                    (<FormControl>this.form.controls['zip']).patchValue(zipCode);
                    (<FormControl>this.form.controls['latitude']).patchValue(location.lat());
                    (<FormControl>this.form.controls['longitude']).patchValue(location.lng());
                    this.drawMap(location.lat(), location.lng(), address);
                }
            });
        });
    }

    drawMap(lat = null, lng = null, address = '') {
        if (lat && lng) {
            this.defaultLocation = { lat: lat, lng: lng };
        }

        const t = this;
        this.mapsAPILoader.load().then(() => {
            const positionLatLng = new google.maps.LatLng(this.defaultLocation.lat, this.defaultLocation.lng);
            t.mapLocation = new google.maps
                .Map((<HTMLInputElement>document.getElementById('map-loc')), {
                    zoom: 15,
                    center: positionLatLng,
                    disableDefaultUI: true,
                    zoomControl: true,
                    fullscreenControl: true
                });
            const styledMapType = AppUtils.getDefaultStyledMapType();
            t.mapLocation.mapTypes.set('styled_map', styledMapType);
            t.mapLocation.setMapTypeId('styled_map');

            if (lat && lng) {
                const marker = new google.maps.Marker({
                    position: positionLatLng,
                    map: t.mapLocation,
                    optimized: false,
                    title: address
                });
            }
        });
    }

    disableFormControl() {
        this.form.get('customer_id').disable();
        this.form.get('type_ids').disable();
    }
}
