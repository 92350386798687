import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phonenumber'
})
export class PhoneNumberpipe implements PipeTransform {
    transform(val) {
        if (val !== null) {
            var viewVal = val.toString().trim().replace(/^\+/, '');
            var area, number;

            switch (viewVal.length) {
                case 1:
                    area = viewVal;
                    break;
                default:
                    area = viewVal.slice(0, 3);
                    number = viewVal.slice(3);
            }

            if (number) {
                if (number.length > 3) {
                    number = number.slice(0, 3) + '-' + number.slice(3, 8);
                } else {
                    number = number;
                }
                return (area + "-" + number).trim().slice(0, 15);
            } else {
                return area ;
            }
        }

        return;
    }
}
