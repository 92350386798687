import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { AuthService } from "../../core/services";
import { AppHelpers, CustomValidators } from "../../core/helpers";
import { first } from "rxjs/operators";

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	providers: [AuthService]
})
export class ForgotPasswordComponent implements OnInit {
	forgotPasswordForm: FormGroup;
	submitted = false;

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		public appHelpers: AppHelpers
	) { }

	ngOnInit() {
		this.forgotPasswordForm = this.formBuilder.group({
			email: ['', CustomValidators.getEmailValidators()],
		});

	}

	get f() { return this.forgotPasswordForm.controls; }

	onSubmit() {
		this.submitted = true;
		if (this.forgotPasswordForm.valid) {
			document.getElementById('loading').classList.add('active');
			this.authService.forgotPassword(this.f.email.value)
				.pipe(first())
				.subscribe(
					data => {
						document.getElementById('loading').classList.remove('active');
						this.appHelpers.showToast('success', data.data);
					},
					error => {
						document.getElementById('loading').classList.remove('active');
						this.appHelpers.showToast('error', error.error.message);
					});
		}
	}
}
