import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../core/services";
import { AppHelpers, CustomValidators } from "../../core/helpers";
import { first } from "rxjs/operators";

@Component({
	selector: 'app-setup-password',
	templateUrl: './setup-password.component.html',
	styleUrls: ['./setup-password.component.scss'],
	providers: [AuthService]
})
export class SetupPasswordComponent implements OnInit {
	setupPasswordForm: FormGroup;
	submitted = false;
	token: string;

	constructor(
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		public appHelpers: AppHelpers
	) {
		this.activatedRoute.queryParams.subscribe(params => {
			this.token = params['token'];
		});
	}

	ngOnInit() {
		this.setupPasswordForm = this.formBuilder.group({
			token: [this.token, Validators.required],
			new_password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
			confirm_password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
		}, {
				validator: SetupPasswordComponent.validate.bind(this)
			});

	}

	static validate(registrationFormGroup: FormGroup) {
		let password = registrationFormGroup.controls.new_password.value;
		let repeatPassword = registrationFormGroup.controls.confirm_password.value;

		if (repeatPassword.length <= 0) {
			return null;
		}

		if (repeatPassword !== password) {
			return {
				doesMatchPassword: true
			};
		}

		return null;
	}

	get f() { return this.setupPasswordForm.controls; }

	onSubmit() {
		this.submitted = true;
		if (this.setupPasswordForm.valid) {
			document.getElementById('loading').classList.add('active');
			this.authService.setupPassword(this.setupPasswordForm.value)
				.pipe(first())
				.subscribe(
					data => {
						document.getElementById('loading').classList.remove('active');
						this.appHelpers.showToast('success', data.message);
						setTimeout(() => {
							this.router.navigateByUrl('/login');
						}, 600);
					},
					error => {
						document.getElementById('loading').classList.remove('active');
						this.appHelpers.showToast('error', error.error.message);
					});
		}
	}
}
