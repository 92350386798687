import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    ignoreStrList = ['ng-option-label', 'ng-option ng-star-inserted ng-option-marked', 'ng-option ng-option-marked', 'ng-option ng-option-selected ng-option-marked'];
	@Output() public clickOutsideEvent = new EventEmitter();

	constructor(private elementRef: ElementRef) { }

	@HostListener('document:click', ['$event.target'])
	public onClick(targetElement) {
		let isClickedOutside = !this.elementRef.nativeElement.contains(targetElement);
		const targetClassName = targetElement.className;
		const ignoreStrList = [...this.ignoreStrList];
		if (targetElement.parentElement.classList.contains('ng-option')) {
			ignoreStrList.push('');
		}
		ignoreStrList.some((o, v) => { // In case, we use some libs to generate DOM elements, the "contains" method above will not suitable! Ex: select2
			if (targetClassName.includes(o)) {
				isClickedOutside = false;
				return true;
			}
		})
		if (isClickedOutside) {
			this.clickOutsideEvent.emit(null);
		}
	}
}
