import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { environment } from '@environment';
import { Subscription } from 'rxjs';

import { AppHelpers } from '../../../core/helpers';
import { DataSharingService } from '../../../shared/services';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    currentUser: any;
    navItemsList: any;
    bsModalRef: BsModalRef;
    profileAvatarPath: string = environment.default_avatar;
    subscriptionUserProfile: Subscription;
    roleCode: any;

    constructor(
        public appHelpers: AppHelpers,
        private dataSharingService: DataSharingService,
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const roleList = JSON.parse(localStorage.getItem('roles'));
        this.roleCode = roleList[0].cd;
    }

    ngOnInit() {
        this.navItemsList = [
            {
                title: 'ĐIỀU VẬN',
                show: this.appHelpers.hasPermission('viewShipment'),
                hasDropdown: true,
                subMenu: [
                    {
                        title: 'CHUYẾN HÀNG',
                        routerLink: ['/operation/shipment'],
                        show: this.appHelpers.hasPermission('viewShipment'),
                        icon: 'fas fa-route'
                    },
                ]
            },
            {
                title: 'Chi phí',
                show: this.appHelpers.hasPermission('viewFeeShipment'),
                hasDropdown: true,
                subMenu: [
                    {
                        title: 'DẦU VÀ CHI PHÍ',
                        routerLink: ['/fee'],
                        show: this.appHelpers.hasPermission('viewFeeShipment'),
                        icon: 'fas fa-route'
                    },
                    {
                        title: 'NHẬP TỆP CHỐT CẤP',
                        routerLink: ['/fee/pannel'],
                        show: this.appHelpers.hasPermission('viewMenuImportShipment'),
                        icon: 'fas fa-sign-in-alt'
                    },
                    {
                        title: 'THỐNG KÊ CÂY DẦU',
                        routerLink: ['/fee/statistic'],
                        show: this.appHelpers.hasPermission('viewStatisticOilStation'),
                        icon: 'fas fa-sign-in-alt'
                    },
                ]
            },
            {
                title: 'Reports',
                show: this.appHelpers.hasPermission('viewMenuExecutiveReport') ||
                    this.appHelpers.hasPermission('viewMenuTruckSalaryReport') ||
                    this.appHelpers.hasPermission('viewMenuWarehouseReport') ||
                    this.appHelpers.hasPermission('viewMenuMaterialReport') ||
                    this.appHelpers.hasPermission('viewMenuTruckConditionReport') ||
                    this.appHelpers.hasPermission('viewMenuSalaryAdvanceReport') ||
                    this.appHelpers.hasPermission('viewMenuTermCostReport'),
                hasDropdown: true,
                subMenu: [
                    {
                        title: 'BÁO CÁO ĐIỀU HÀNH',
                        routerLink: ['/report/execution-report'],
                        show: this.appHelpers.hasPermission('viewMenuExecutiveReport'),
                        icon: 'fas fa-cubes'
                    },
                    {
                        title: 'BÁO CÁO LƯƠNG XE TẢI',
                        routerLink: ['/report/truck-salary-report'],
                        show: this.appHelpers.hasPermission('viewMenuTruckSalaryReport'),
                        icon: 'fas fa-cubes'
                    },
                    // {
                    //     title: 'Báo cáo tài xế',
                    //     routerLink: ['/report/driver'],
                    //     show: this.appHelpers.hasPermission('viewDriverReport'),
                    //     icon: 'fas fa-cubes'
                    // },
                    {
                        title: 'BÁO CÁO KHO',
                        routerLink: ['/report/inventory'],
                        show: this.appHelpers.hasPermission('viewMenuWarehouseReport'),
                        icon: 'fas fa-cubes'
                    },
                    {
                        title: 'BÁO CÁO VẬT TƯ',
                        routerLink: ['/report/list-carton'],
                        show: this.appHelpers.hasPermission('viewMenuMaterialReport'),
                        icon: 'fas fa-cubes'
                    },
                    {
                        title: 'BÁO CÁO NHẬP KHO',
                        routerLink: ['/report/inbound'],
                        show: this.appHelpers.hasPermission('viewPoReport'),
                        icon: 'fas fa-cubes'
                    },
                    {
                        title: 'BÁO CÁO XUẤT KHO',
                        routerLink: ['/report/outbound'],
                        show: this.appHelpers.hasPermission('viewObReport'),
                        icon: 'fas fa-cubes'
                    },
                    {
                        title: 'BÁO CÁO TÌNH TRẠNG XE',
                        routerLink: ['/report/report-trucks'],
                        show: this.appHelpers.hasPermission('viewMenuTruckConditionReport'),
                        icon: 'fas fa-cubes'
                    },
                    {
                        title: 'BÁO CÁO ỨNG LƯƠNG',
                        routerLink: ['/report/report-salary-advance'],
                        show: this.appHelpers.hasPermission('viewMenuSalaryAdvanceReport'),
                        icon: 'fas fa-cubes'
                    },

                    {
                        title: 'BÁO CÁO CHI PHÍ KHOÁN - CHỐT CẤP',
                        routerLink: ['/report/report-provided'],
                        show: this.appHelpers.hasPermission('viewMenuTermCostReport'),
                        icon: 'fas fa-cubes'
                    },
                ]
            },
            {
                title: 'Kế Toán',
                show: this.appHelpers.hasPermission('viewMenuMaterialAccountant') ||
                    this.appHelpers.hasPermission('viewMenuRepairAccountant') ||
                    this.appHelpers.hasPermission('viewMenuSalaryAccountant') ||
                    this.appHelpers.hasPermission('viewMenuCompareAccountant') ||
                    this.appHelpers.hasPermission('viewMenuBilling') ||
                    this.appHelpers.hasPermission('viewStatisticShipment') ||
                    this.appHelpers.hasPermission('viewSalaryAdvances'),
                hasDropdown: true,
                subMenu: [
                    {
                        title: 'THỐNG KÊ CHUYẾN HÀNG',
                        routerLink: ['/accounting/shipments'],
                        show: this.appHelpers.hasPermission('viewStatisticShipment'),
                        icon: 'fas fa-file-invoice'
                    },
                    {
                        title: 'KẾ TOÁN VẬT TƯ',
                        routerLink: ['/accounting/materials-accounting'],
                        show: this.appHelpers.hasPermission('viewMenuMaterialAccountant'),
                        icon: 'fas fa-file-invoice'
                    },
                    {
                        title: 'KẾ TOÁN SỬA CHỮA',
                        routerLink: ['/accounting/repair-accounting'],
                        show: this.appHelpers.hasPermission('viewMenuRepairAccountant'),
                        icon: 'fas fa-tools'
                    },
                    {
                        title: 'KẾ TOÁN LƯƠNG - CHI PHÍ - DOANH THU',
                        routerLink: ['/accounting/report'],
                        show: this.appHelpers.hasPermission('viewMenuSalaryAccountant'),
                        icon: 'fas fa-file-alt'
                    },
                    {
                        title: 'KẾ TOÁN DẦU CHỐT CẤP',
                        routerLink: ['/accounting/oils'],
                        show: this.appHelpers.hasPermission('viewAccountantOil'),
                        icon: 'fa fa-gas-pump'
                    },
                    {
                        title: 'KẾ TOÁN ĐỐI CHIẾU',
                        routerLink: ['/accounting/compare'],
                        show: this.appHelpers.hasPermission('viewMenuCompareAccountant'),
                        icon: 'fas fa-copy'
                    },
                    {
                        title: 'KẾ TOÁN ỨNG LƯƠNG',
                        routerLink: ['/accounting/salary'],
                        show: this.appHelpers.hasPermission('viewSalaryAdvances'),
                        icon: 'fas fa-file-invoice-dollar'
                    },
                    {
                        title: 'QUẢN LÝ PHIẾU THU',
                        routerLink: ['/accounting/billing'],
                        show: this.appHelpers.hasPermission('viewMenuBilling'),
                        icon: 'fas fa-money-check-alt'
                    }
                ]
            },
            {
                title: 'Admin',
                routerLink: ['/admin'],
                show: true,
                hasDropdown: false
            },
        ];

        this.subscriptionUserProfile = this.dataSharingService.currentUserProfile.subscribe(userProfile => {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            this.profileAvatarPath = currentUser['avatar_thumb'] ? environment.url_avatar + currentUser['avatar_thumb'] : this.profileAvatarPath;
        });
    }
}
