import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { AppHelpers } from "../../../../core/helpers";
import { UserService } from "../user.service";

@Component({
    selector: 'app-user-create',
    templateUrl: './user-create.component.html',
    styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
    title: string;
    form: FormGroup;
    submitted = false;
    roles: any = [];
    departments: any[];
    listCountries: any;
    datetimepickerOptionsDay = {};
    datetimepickerOptions = {};
    datetimepickerOptionsMaxDate = {};
    companyjoindtValid = false;
    dayoffnumValid = false;
    identitycarddtValid = false;
    dateOff: any;
    dateJoin: any;
    dateIdentity: any;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private _title: Title,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public appHelpers: AppHelpers,
        private translate: TranslateService
    ) {
        this.activatedRoute.data.subscribe(v => {
            this.title = 'Thêm mới nhân viên';
        });
        this._title.setTitle(this.translate.instant(this.title));
    }

    ngOnInit() {
        this.datetimepickerOptionsDay = this.appHelpers.configDateCommon();
        this.datetimepickerOptions = this.appHelpers.configDateCommon();
        this.datetimepickerOptionsMaxDate = this.appHelpers.configDateCommon();
        this.datetimepickerOptionsMaxDate['useCurrent'] = false;
        this.datetimepickerOptionsMaxDate['maxDate'] = new Date();

        this.buildForm();
        this.getRole();
        this.getlistDepartment();
    }

    buildForm() {
        this.form = this.formBuilder.group({
            first_name: [null, Validators.compose([Validators.required, Validators.maxLength(30)])],
            last_name: [null, Validators.compose([Validators.required, Validators.maxLength(30)])],
            email: [null, Validators.compose([
                Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
                Validators.maxLength(64),
                Validators.required
            ])],
            phone: [null, Validators.compose([Validators.required, Validators.maxLength(20)])],
            dt_of_birth: [null],
            identity_card_num: [null, Validators.compose([Validators.required, Validators.maxLength(15)])],
            identity_card_dt: [null],
            identity_card_address: [null, Validators.required],
            identity_card_by: [null, Validators.required],
            company_join_dt: [null],
            day_off_num: [null],
            department: [null],
            position: [null],
            type_of_contract: [null],
            insurance_dt: [null],
            note: [null],
            status: [1],
            address_now: [null],
            phone_company: [null],
        });
    }

    getRole() {
        this.userService.getRoles('?ac=1').subscribe(result => {
            this.roles = result['data'].filter((role) => {
                if (role.cd != 'CU' && role.cd != 'DR') return role;
            })
        }, err => {
            console.log(err);
        });
    }

    getlistDepartment() {
        this.userService.getlistDepartment().subscribe(result => {
            // @ts-ignore
            this.departments = result.data;
            // this.departments = this.departments.filter((departments) => {
            //     return this.checkValidRoles(departments.cd);
            // })
        }, err => {
            console.log(err);
        });
    }

    checkValidRoles(roleCode) {
        let result = false;
        switch (roleCode) {
            case 'AD':
            case 'EM':
                result = true;
                break;
            default:
                result = this.appHelpers.hasPermission('hasFullRole');
                break;
        }
        return result;
    }

    onSubmit() {
        this.submitted = true;

        if (this.form.valid) {
            const element = this.form.getRawValue();
            element['insurance_dt'] = this.dateOff;
            element['company_join_dt'] = this.dateJoin;
            element['identity_card_dt'] = this.dateIdentity;

            this.userService.createUser(element).subscribe(() => {
                this.submitted = false;
                this.appHelpers.showToast('success', this.translate.instant('Thêm mới nhân viên thành công'));
                setTimeout(() => {
                    this.router.navigateByUrl('/admin/user');
                }, 600);
            }, err => {
                this.submitted = false;
                this.appHelpers.showToast('error', err.message);
            });
        }
    }

    changeDateInsurance(event) {
        if (event.target.value != '' && !this.appHelpers.isDateValid(event.target.value)) {
            this.dayoffnumValid = true;
        } else {
            this.dayoffnumValid = false;
        }

        if (event.target.value == '') {
            this.dateOff = null;
        } else {
            this.dateOff = event.target.value.split("/").reverse().join("-");
        }
    }

    changeDateJoin(event) {
        if (event.target.value != '' && !this.appHelpers.isDateValid(event.target.value)) {
            this.companyjoindtValid = true;
        } else {
            this.companyjoindtValid = false;
        }

        if (event.target.value == '') {
            this.dateJoin = null;
        } else {
            this.dateJoin = event.target.value.split("/").reverse().join("-");
        }
    }

    changeDateIdentity(event) {
        if (event.target.value != '' && !this.appHelpers.isDateValid(event.target.value)) {
            this.identitycarddtValid = true;
        } else {
            this.identitycarddtValid = false;
        }

        if (event.target.value == '') {
            this.dateIdentity = null;
        } else {
            this.dateIdentity = event.target.value.split("/").reverse().join("-");
        }
    }

    cancel() {
        this.router.navigateByUrl('/admin/user');
    }
}
