import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppHelpers, AppUtils } from '../../core/helpers';
import { MarkerManager, AgmMap } from "@agm/core";
import { GPSTrackingService } from 'src/app/feature/gps-tracking/gps-tracking.service';

const iconLive = {
	url: '../../../assets/images/truck-marker-live.png',
	scaledSize: {
		width: 30,
		height: 30,
		fillOpacity: 0.8,
		strokeWeight: 1
	}
};
@Component({
	selector: 'drivers-online-modal',
	templateUrl: 'drivers-online.modal.html',
	styleUrls: ['driver-online.component.scss']
})

export class DriversOnlineModal implements OnInit {
	zoom: number = 15;
	modalTitle = 'Drivers Online'
	public onClose: Subject<Object>;
	listDriversOnline: any
	driverDetails: any;
	hasLocation: boolean = false;
	mapTypeStyleArray = AppUtils.getDefaultMapTypeStyleArray();
	roleCode: any;
	companyId: any;

	constructor(
		private gpsTrackingService: GPSTrackingService,
		public appHelpers: AppHelpers,
		public bsModalRef: BsModalRef
	) {
		const roleList = JSON.parse(localStorage.getItem('roles'));
		this.roleCode = roleList[0].cd;
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		this.companyId = currentUser.company_id;
	}

	ngOnInit() {
		this.onClose = new Subject();
	}

	getCurrentLocation(item) {
		this.getDriversOnline(item.id);
	}
	getDriversOnline(driverId) {
		const params = driverId ? ('/' + driverId) : '';
		this.gpsTrackingService.getDriversGPSTracking(params)
			.subscribe((data: any) => {
				this.driverDetails = (data.data && data.data instanceof Array && data.data.length > 0)
					? data.data[0] : [];
				this.hasLocation = true;
			}, error => {
				this.appHelpers.showToast('error', error.message);
				this.hasLocation = false;
			});
	}

}
