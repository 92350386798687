import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { SearchService } from "./core/services/search.service";
import { TranslateService } from "@ngx-translate/core";
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
	selector: 'app-root',
	template: `
      <router-outlet>
          <div class="loading-overlay" id="loading" [class.active]="loading">
              <div class="cssload-thecube">
                  <div class="cssload-cube cssload-c1"></div>
                  <div class="cssload-cube cssload-c2"></div>
                  <div class="cssload-cube cssload-c4"></div>
                  <div class="cssload-cube cssload-c3"></div>
              </div>
          </div>
      </router-outlet>`
})
export class AppComponent {
	loading: boolean;

	constructor(
		private router: Router,
		private searchService: SearchService,
		private translate: TranslateService,
		private config: NgSelectConfig
	) {
		this.loading = true;
		this.translate.addLangs(['en', 'he', 'vi']);
		let defaultLangCode: string;

		if (localStorage.getItem('lang')) {
			defaultLangCode = localStorage.getItem('lang');

			const dom: any = document.querySelector('body');
			if (defaultLangCode == 'he') {
				dom.classList.add('rtl');
			}
		} else {
			localStorage.setItem('lang', 'vi')
		}

		this.translate.setDefaultLang(defaultLangCode);
		this.translate.use(defaultLangCode);

		setTimeout(() => {
			this.config.notFoundText = this.translate.instant('NG_SELECT_No_Items_Found');
			this.config.typeToSearchText = this.translate.instant('NG_SELECT_Type_To_Search');
			this.config.addTagText = this.translate.instant('NG_SELECT_Add_Item');
			this.config.loadingText = this.translate.instant('NG_SELECT_Loading');
			this.config.clearAllText = this.translate.instant('NG_SELECT_Clear_All');
		}, 5000);
	}

	ngAfterViewInit() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.loading = true;

				if (this.searchService.getUrl(event) !== sessionStorage.getItem('urlCurrent')) {
					sessionStorage.removeItem("filter");
				}
			} else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
				this.loading = false;
			}
		});
	}
}
