import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';

@Component({
	selector: 'app-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PageHeaderComponent implements OnInit {
	@Input() showBackIcon: boolean;
	@Input() heading: string;

	@Input() showFilterIcon: boolean;
	@Output() togglePageFilterEvent = new EventEmitter();

	@Input() searchFormObj = {};
	@Output() removeFilterEvent = new EventEmitter();
	objectKeys = Object.keys;

	constructor(
		private location: Location
	) { }

	ngOnInit() { }

	goBack() {
		this.location.back();
	}

	togglePageFilter(event) {
		event.preventDefault();
		event.stopPropagation();
		this.togglePageFilterEvent.emit(null);
	}

	removeFilter(filterName) {
		this.searchFormObj[filterName]['value'] = null;
		this.removeFilterEvent.emit(filterName);
	}

	isString(val) {
		return typeof val === 'string';
	}
}
