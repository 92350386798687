import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

@Directive({
    selector: '[numericType]',
    providers: [NgModel,]
})

export class NumericDirective {

    @Input('numericType') numericType: string; // number | decimal
    @Input() maxDecimalFractionLength: any;
    @Input() maxlength: any; // default attribute
    @Input() min: any;
    @Input() max: any;

    private regex = {
        number: new RegExp(/^\d+$/),
        decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
    };

    private specialKeys = {
        number: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
        decimal: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
    };

    constructor(private el: ElementRef, private ngModel: NgModel, ) {
    }
    @HostListener('focus', ['$event'])
    onFocus($event) {
        if (this.el.nativeElement) {
            this.el.nativeElement.select();
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(element) {
        if (isNaN(+element.target.value)) {
            this.ngModel.viewToModelUpdate(0);
            this.ngModel.valueAccessor.writeValue(0);
        }

        if (this.min !== undefined && element.target.value === '') {
            this.ngModel.viewToModelUpdate(this.min);
            this.ngModel.valueAccessor.writeValue(this.min);
        }

        if (this.max !== undefined && element.target.value && +element.target.value > +this.max) {
            this.ngModel.viewToModelUpdate(this.max);
            this.ngModel.valueAccessor.writeValue(this.max);
        }
    }


    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {

        if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex[this.numericType])) {
            event.preventDefault();
            return;
        }

        // Validate for decimal part on a decimal number
        const e = <any>event;
        const str = e.target.value;
        const inputType = window.getSelection().type;
        if (inputType != 'Range') {
            if (this.maxlength) {
                if (str.includes('.')) {
                    const t3 = str.split('.');
                    const t4 = parseInt(this.maxDecimalFractionLength);
                    if ((t3[1] && t3[1].length >= t4) || (t3[1] && t3[1].length >= 0 && t3[0] && t3[0].length > (this.maxlength - this.maxDecimalFractionLength))) {
                        e.preventDefault();
                        return;
                    }
                }
            }
        }
    }
}
