import { IStatus } from "./i-status";
import { IUser } from "./i-user";

export class INote {
    id: number;
    content: string;
    statusId: number;
    createdBy: number;
    updatedBy: number;
    createdAt: string;
    updatedAt: string;
    status: IStatus;
    files: any[];
    user: IUser;

    constructor(json = {}) {
        Object.assign(this, json);
        this.status = new IStatus(this.status);
        this.user = new IUser(this.user);
    }

    static decodeAPIJson(responeAPI = <any>{}): INote {
        const model = new INote();
        model.id = responeAPI.id;
        model.content = responeAPI.content;
        model.statusId = responeAPI.status_id;
        model.createdBy = responeAPI.created_by;
        model.updatedBy = responeAPI.updated_by;
        model.createdAt = responeAPI.created_at;
        model.updatedAt = responeAPI.updated_at;
        model.status = IStatus.decodeAPIJson(responeAPI.status);
        model.files = responeAPI.files;
        model.user = IUser.decodeAPIJson(responeAPI.user);
        return new INote(model);
    }

    static decodeAPIJsonList(responeAPI = []): INote[] {
        return responeAPI.map(item => {
            return INote.decodeAPIJson(item);
        });
    }

    static copyList(array: any[] = []): INote[] {
        return array.map((el: any) => {
            return new INote(el);
        });
    }

}



