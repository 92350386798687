import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderNoteUiComponent } from './order-note-ui/order-note-ui.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  exports: [
    OrderNoteUiComponent
  ],
  declarations: [OrderNoteUiComponent]
})
export class OrderNoteModule { }
