import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../core/services';
import { AppHelpers, AppUtils, CustomValidators } from "../../core/helpers";
import { TranslateService } from '@ngx-translate/core';
declare const $: any;

@Component({
	selector: 'app-register',
	templateUrl: 'register.component.html',
	styleUrls: ['./register.component.scss'],
	providers: [AuthService]
})
export class RegisterComponent implements OnInit {
	registerForm: FormGroup;
	submitted = false;
	selectedRegistrantCountry: any;
	selectedCompanyCountry: any;
	listCountries: any;
	objectKeys = Object.keys;
	companyFiles = [];

	constructor(
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private translate: TranslateService,
		public appHelpers: AppHelpers
	) { }

	ngOnInit() {
		this.buildForm();
		this.appHelpers.getCountriesMasterdata().subscribe((result) => {
			this.listCountries = result;
			this.selectedRegistrantCountry = this.appHelpers.getCountryInfoByISOCode(this.listCountries);
			this.selectedCompanyCountry = this.appHelpers.getCountryInfoByISOCode(this.listCountries);
		})
	}

	buildForm() {
		this.registerForm = this.formBuilder.group({
			first_name: [null, Validators.compose([Validators.required, Validators.maxLength(30), Validators.minLength(2)])],
			last_name: [null, Validators.compose([Validators.required, Validators.maxLength(30), Validators.minLength(2)])],
			phone: [null],
			email: [null, CustomValidators.getEmailValidators()],
			password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
			confirm_password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
			company_name: [null, Validators.compose([Validators.required, Validators.maxLength(30), Validators.minLength(2)])],
			company_phone: [null],
			company_email: [null, CustomValidators.getEmailValidators()],
			company_address: [null, Validators.compose([Validators.required, Validators.maxLength(255), Validators.minLength(10)])],
			register_type: [1, Validators.required],
			term: [0, Validators.required]
		}, {
				validator: [
					CustomValidators.matchPasswordRegister
				],
			});
	}

	changeRegistrantCountry(countryISOCode) {
		this.selectedRegistrantCountry = this.appHelpers.getCountryInfoByISOCode(this.listCountries, countryISOCode);
	}

	changeCompanyCountry(countryISOCode) {
		this.selectedCompanyCountry = this.appHelpers.getCountryInfoByISOCode(this.listCountries, countryISOCode);
	}

	handleCompanyFiles(files: FileList) {
		this.companyFiles.push(files.item(0));
	}

	formatPhoneNumber() {
		//Reset the format first
		if (this.registerForm.value['phone']) {
			const representativePhoneNumber = parseInt(this.registerForm.value['phone'].replace(this.selectedRegistrantCountry.countryCode, ''));
			if (representativePhoneNumber) {
				this.registerForm.controls['phone'].setValue(this.selectedRegistrantCountry.countryCode + representativePhoneNumber);
			}
		}

		if (this.registerForm.value['company_phone']) {
			const companyPhoneNumber = parseInt(this.registerForm.value['company_phone'].replace(this.selectedCompanyCountry.countryCode, ''));
			if (companyPhoneNumber) {
				this.registerForm.controls['company_phone'].setValue(this.selectedCompanyCountry.countryCode + companyPhoneNumber);
			}
		}
	}

	onSubmit() {
		this.submitted = true;
		if (!this.registerForm.get('term').value) {
			this.appHelpers.showToast('error', this.translate.instant('Please agree with the Terms of Service'));
			return;
		}

		// this.formatPhoneNumber();
		if (this.registerForm.valid) {
			const params = this.registerForm.getRawValue();
			const formData = new FormData();

			for (let i = 0; i < this.companyFiles.length; i++) {
				formData.append('certificate_files[]', this.companyFiles[i], this.companyFiles[i].name);
			}

			for (let key in params) {
				if ($.inArray(key, ['term', 'confirm_password']) >= 0) {
					continue;
				} else {
					formData.append(key, params[key]);
				}
			}

			this.authService.register(formData).subscribe(
				data => {
					this.submitted = false;
					this.appHelpers.showToast('success', this.translate.instant('Submitted successfully'));
					setTimeout(() => {
						this.router.navigate(['/login']);
					}, 600);
				},
				error => {
					this.submitted = false;
					this.appHelpers.showToast('error', error.error.message);
				});
		}
	}
}
