import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppUtils } from '../../helpers';
import { environment } from '@environment';

@Component({
    selector: 'app-language-dropdown',
    providers: [TranslateService],
    templateUrl: 'language-dropdown.component.html',
    styleUrls: ['language-dropdown.component.scss']
})
export class LanguageDropdownComponent {
    langs = environment.lang;
    langText = 'Việt Nam';
    currentLanguageCode = AppUtils.getCurrentLanguageCode();

    constructor(private translate: TranslateService, public router: Router) {

        this.translate.addLangs(this.langs.map(lang => lang.code));
        let defaultLangCode = 'vi';
        if (localStorage.getItem('lang')) {
            defaultLangCode = localStorage.getItem('lang');
            const dom: any = document.querySelector('body');
            if (defaultLangCode === 'he') {
                dom.classList.add('rtl');
            }
        } else {
            localStorage.setItem('lang', defaultLangCode);
        }
        this.translate.setDefaultLang(defaultLangCode);
        this.translate.use(defaultLangCode);
        this.langText = this.langs.filter(lang => lang.code === defaultLangCode)[0].name;
    }

    changeLangCode(langCode: any) {
        const dom: any = document.querySelector('body');
        this.translate.use(langCode);
        localStorage.setItem('lang', langCode);
        this.langText = this.langs.filter(lang => lang.code === langCode)[0].name;
        if (langCode === 'he') {
            dom.classList.add('rtl');
        } else {
            dom.classList.remove('rtl');
        }

        window.location.reload();
    }
}
