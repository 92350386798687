import { Directive, Input, HostListener, ElementRef, OnInit } from '@angular/core';
import { NgModel, NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import * as _ from 'lodash';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
@Directive({
    selector: '[configNumber]',
    providers: [NgModel, DecimalPipe,]
})
export class ConfigNumberDirective implements OnInit {
    @Input() min: any;
    @Input() max: any;
    @Input() maxDecimalFractionLength = 2;
    @Input() isDecimal;
    @Input() isCurrency;
    @Input() allowNegative;
    @Input() max_limit_value = 1000000000000;
    private init: boolean;
    private beforeValue = 0;
    constructor(private el: ElementRef, private ngModel: NgModel, private decimalPipe: DecimalPipe, private formControl: NgControl, ) { }

    @HostListener('focus', ['$event'])
    onFocus($event) {
        if (this.el.nativeElement) {
            this.el.nativeElement.select();
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(element) {
        const event = +element.target.value.toString().replace(/,/g, '');

        this.ngModel.viewToModelUpdate(this.formatValue(event));
        this.ngModel.valueAccessor.writeValue(this.formatValue(event));

        if (this.el.nativeElement.attributes['formcontrolname']) {
            if (event > this.max_limit_value) {
                this.formControl.control.setValue(this.formatValue(this.max_limit_value));
            }

            if (this.min !== undefined && event < +this.min) {
                this.formControl.control.setValue(this.formatValue(this.isDecimal === true ? this.min : parseInt(this.min, 10)));
            }

            if (this.max !== undefined && event > +this.max) {
                this.formControl.control.setValue(this.formatValue(this.isDecimal === true ? this.max : parseInt(this.max, 10)));
            }
        } else {
            if (event > this.max_limit_value) {
                this.ngModel.viewToModelUpdate(this.formatValue(this.max_limit_value));
                this.ngModel.valueAccessor.writeValue(this.formatValue(this.max_limit_value));
            }

            if (this.min !== undefined && event < +this.min) {
                this.ngModel.viewToModelUpdate(this.formatValue(this.isDecimal === true ? this.min : parseInt(this.min, 10)));
                this.ngModel.valueAccessor.writeValue(this.formatValue(this.isDecimal === true ? this.min : parseInt(this.min, 10)));
            }

            if (this.max !== undefined && event > +this.max) {
                this.ngModel.viewToModelUpdate(this.formatValue(this.isDecimal === true ? this.max : parseInt(this.max, 10)));
                this.ngModel.valueAccessor.writeValue(this.formatValue(this.isDecimal === true ? this.max : parseInt(this.max, 10)));
            }
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event) {
        // this.characterInput = event.key;
        // this.beforeValue = +event.target.value.replace(/,/g, '');
        if (!this.allowNegative && event['key'] === '-') {
            event.preventDefault();
        }
        if (!this.isCurrency && !this.isDecimal && event['key'] === '.') {
            event.preventDefault();
        }
    }

    @HostListener('input', ['$event'])
    onInputChange(event) {
        // const value = +event.target.value.replace(/,/g, '');
        // if (this.characterNumber.indexOf(this.characterInput) >= 0) {
        //     if (value > this.max_limit_value) {
        //         this.ngModel.viewToModelUpdate(this.beforeValue);
        //         this.ngModel.valueAccessor.writeValue(this.beforeValue);
        //     }

        //     if (this.min !== undefined && value < this.min) {
        //         this.ngModel.viewToModelUpdate(this.min);
        //         this.ngModel.valueAccessor.writeValue(this.min);
        //     }

        //     if (this.max !== undefined && value > +this.max) {
        //         this.ngModel.viewToModelUpdate(this.max);
        //         this.ngModel.valueAccessor.writeValue(this.max);
        //     }
        // }
    }

    ngOnInit() {
        if (!this.isCurrency) {
            if (this.el.nativeElement.attributes['formcontrolname']) {
                const times = interval(100);
                const takeTenTime = times.pipe(take(12));
                takeTenTime.subscribe((time) => {
                    if (this.formControl.control.value !== null && this.formControl.control.value !== undefined) {
                        const valueFormated = this.decimalPipe.transform(+this.formControl.control.value, '.0-' + this.maxDecimalFractionLength);
                        this.formControl.control.setValue(valueFormated);
                    }
                });
            } else {
                setTimeout(() => {
                    if (this.ngModel.value !== null && this.ngModel.value !== undefined) {
                        const valueFormated = this.decimalPipe.transform(this.ngModel.value, '.0-' + this.maxDecimalFractionLength);
                        this.ngModel.viewToModelUpdate(valueFormated);
                        this.ngModel.valueAccessor.writeValue(valueFormated);
                    }
                }, 10);
            }
        } else {
            if (this.el.nativeElement.attributes['formcontrolname']) {
                const times = interval(100);
                const takeTenTime = times.pipe(take(12));
                takeTenTime.subscribe((time) => {
                    if (this.formControl.control.value !== null && this.formControl.control.value !== undefined) {
                        const valueFormated = this.decimalPipe.transform(+this.formControl.control.value, '.2-' + this.maxDecimalFractionLength);
                        this.formControl.control.setValue(valueFormated);
                    }
                });
            } else {
                setTimeout(() => {
                    if (this.ngModel.value !== null && this.ngModel.value !== undefined) {
                        const valueFormated = this.decimalPipe.transform(this.ngModel.value, '.2-' + this.maxDecimalFractionLength);
                        this.ngModel.viewToModelUpdate(valueFormated);
                        this.ngModel.valueAccessor.writeValue(valueFormated);
                    }
                }, 10);
            }
        }
    }

    formatValue(value): String {
        return this.isCurrency ? this.decimalPipe.transform(value, '.2-' + this.maxDecimalFractionLength) : value;
    }
}
