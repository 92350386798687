import { NgModule } from '@angular/core';
import { SharedModule } from "../shared/shared.module";
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from "./components/header/header.component";
import { ImagesViewerComponent } from "./components/images-viewer/images-viewer.component";
import { LanguageDropdownComponent } from "./components/language-dropdown/language-dropdown.component";
import { ScrollTopComponent } from "./components/scroll-top/scroll-top.component";
import { OrderNoteModule } from './components/order-note/order-note.module';
import { NgbdModalConfirm } from './components/confirmation-dialog/confirmation-dialog.component';
import { NgbdModalConfirmReset } from './components/confirmation-reset/confirmation-reset.component';

@NgModule({
	imports: [
		SharedModule,
		TranslateModule,
		NgbTooltipModule,
		OrderNoteModule
	],
	exports: [
		SharedModule,
		TranslateModule,
		NgbTooltipModule,
		HeaderComponent,
		ImagesViewerComponent,
		LanguageDropdownComponent,
		ScrollTopComponent
	],
	declarations: [
		HeaderComponent,
		ImagesViewerComponent,
		LanguageDropdownComponent,
		NgbdModalConfirm,
		NgbdModalConfirmReset,
		ScrollTopComponent
	],
	entryComponents: [
		NgbdModalConfirm,
		NgbdModalConfirmReset
	]
})
export class CoreModule {
}
