import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-error-message',
	templateUrl: './error-message.component.html',
	styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
	@Input() showErrorMessage: boolean;
	@Input() errorMessageList: any;

	constructor() { }

	ngOnInit() { }

	ngOnChanges() { }

	dismissMessage() {
		this.showErrorMessage = false;
	}

}
