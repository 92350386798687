import { NgModule, ModuleWithProviders, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap';
import { CarouselModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap';
import { TabsModule } from 'ngx-bootstrap';
import { NgTempusdominusBootstrapModule } from 'ngx-tempusdominus-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { DragulaModule } from 'ng2-dragula';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { FileDropzoneComponent } from './components/file-dropzone/file-dropzone.component';
import { StatusBarComponent } from './components/status-bar/status-bar.component';
import { UserCreateComponent } from '../feature/admin/user/user-create/user-create.component';
import { LocationAutoCompleteComponent } from './components/location-auto-complete/location-auto-complete.component';
import { environment } from '../../environments/environment';
import { AgmDirectionModule } from 'agm-direction';
import { AvatarPreviewModal } from './modals/avatar-preview.modal';
import { ConfirmationModal } from './modals/confirmation.modal';
import { DriversOnlineModal } from './modals/drivers-online.modal';
import { LocationCreateModalComponent } from './modals/location-create.modal';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { NumberDirective } from './directives/number.directive';
import { OnlyDigitsDirective } from './directives/only-digits.directive';
import { NumericDirective } from './directives/only-numeric.directive';
import { SpecialCharacterDirective } from './directives/specialChracter.directive';
import { DataSharingService } from './services/data-sharing.service';
import { GoogleplaceDirective } from './directives/googlePlace.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfigNumberDirective } from './directives/config-number.directive';
import { BlockEnterSpecialCharactersDirective } from './directives/block-enter-special-characters.directive';
import { PhoneNumberpipe } from '../shared/pipes/phone-number-pipe';
import { SalaryPipe } from '../shared/pipes/salary-pipe';
import { ButtonComponent } from 'src/app/shared/components/atoms/button/button.component';

@Injectable()
export class GoogleMapsConfig {
    apiKey: string;
    libraries: any;
    region: any;
    language: any;
    constructor() {
        this.apiKey = environment.ggAppKey;
        this.libraries = ['geometry', 'places'];
        this.region = localStorage && localStorage.lang === 'he' ? 'IL' : 'EN';
        this.language = localStorage && localStorage.lang === 'he' ? 'iw' : 'en';
    }
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        // AgmCoreModule.forRoot({
        // 	apiKey: environment.ggAppKey,
        // 	libraries: ['geometry', 'places'],
        // 	region: localStorage && localStorage.lang == 'he' ? 'IL' : 'EN',
        // 	language: localStorage && localStorage.lang == 'he' ? 'iw' : 'en',
        // }),
        AgmCoreModule.forRoot(),
        AgmDirectionModule,
        ChartsModule,
        BsDropdownModule.forRoot(),
        CarouselModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        NgTempusdominusBootstrapModule,
        FileUploadModule,
        DragulaModule,
        DropzoneModule,
        TranslateModule,
        NgSelectModule
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AgmCoreModule,
        ChartsModule,
        AgmDirectionModule,
        BsDropdownModule,
        CarouselModule,
        ModalModule,
        TabsModule,
        NgTempusdominusBootstrapModule,
        FileUploadModule,
        DragulaModule,
        DropzoneModule,
        ErrorMessageComponent,
        PageHeaderComponent,
        FileDropzoneComponent,
        StatusBarComponent,
        UserCreateComponent,
        LocationAutoCompleteComponent,
        AvatarPreviewModal,
        ConfirmationModal,
        DriversOnlineModal,
        LocationCreateModalComponent,
        ClickOutsideDirective,
        OnlyDigitsDirective,
        NumericDirective,
        SpecialCharacterDirective,
        GoogleplaceDirective,
        NgSelectModule,
        NumberDirective,
        ConfigNumberDirective,
        BlockEnterSpecialCharactersDirective,
        PhoneNumberpipe,
        SalaryPipe,
        ButtonComponent,
    ],
    declarations: [
        ErrorMessageComponent,
        ButtonComponent,
        PageHeaderComponent,
        FileDropzoneComponent,
        StatusBarComponent,
        UserCreateComponent,
        LocationAutoCompleteComponent,
        AvatarPreviewModal,
        ConfirmationModal,
        DriversOnlineModal,
        LocationCreateModalComponent,
        ClickOutsideDirective,
        OnlyDigitsDirective,
        NumericDirective,
        SpecialCharacterDirective,
        GoogleplaceDirective,
        NumberDirective,
        ConfigNumberDirective,
        BlockEnterSpecialCharactersDirective,
        PhoneNumberpipe,
        SalaryPipe
    ],
    entryComponents: [
        AvatarPreviewModal,
        ConfirmationModal,
        DriversOnlineModal,
        LocationCreateModalComponent
    ],
    providers: [
        { provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig }
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                DataSharingService
            ]
        };
    }
}
