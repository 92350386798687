import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ngbd-modal-confirm-reset',
    templateUrl: './confirmation-reset.component.html'
})

export class NgbdModalConfirmReset {
    title = 'Bạn có chắc là muốn đặt lại mật khẩu cho người dùng này?';

    constructor(public modal: NgbActiveModal) { }
}
