import { Injectable } from '@angular/core';
import { HttpService } from "../../../core/services";
import { environment } from "../../../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private prefix: string;

	constructor(private http: HttpService) {
		this.prefix = environment.prefixApi.auth;
	}

	getUserList(param = '') {
		return this.http.Get(this.prefix + `/admin/users` + param);
	}

	createUser(data: any) {
		return this.http.Post(this.prefix + `/admin/users`, data);
	}

	getUserDetails(id: number) {
		return this.http.Get(this.prefix + `/admin/users/` + id);
	}

	editUser(id: number, data: Object) {
		return this.http.Put(this.prefix + `/admin/users/` + id, data);

		// Use Post instead of Put to send request with Form Data
		// return this.http.Post(this.prefix + `/admin/users/` + id, data);
	}

	deleteUser(id: number) {
		return this.http.Delete(this.prefix + `/admin/users/` + id);
	}

	getRoles(param = '') {
		return this.http.Get(this.prefix + `/admin/roles` + param);
    }

    getlistDepartment(param = '') {
		return this.http.Get(this.prefix + `/admin/users/list-department` + param);
	}

	getSupervisorList(param = '') {
		return this.http.Get(this.prefix + `/admin/users/supervisors` + param);
	}

	resetPass(data: any) {
		return this.http.Put(this.prefix + `/user/reset-password`, data);
	}

    assignRole(data: any) {
		return this.http.Put(this.prefix + `/admin/assign-role/users`, data);
	}

	offWorking(data: any) {
		return this.http.Put(this.prefix + `/admin/off/user-off`, data);
	}
}
