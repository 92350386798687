import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from "../../../environments/environment";

@Injectable()
export class SocketService {

	public socketUrl;
	public socket: { [uniqueKey: string]: any };

	constructor() {
		this.socketUrl = environment.baseSocketUrl;
		// this.socket = io(this.socketUrl, {
		// 	path: "/session/socket.io",
		// 	query: {
		// 		token: localStorage.getItem('jwt')
		// 	}
		// });
	}

	initSocket() {
		this.socket = io(this.socketUrl, {
			path: "/session/socket.io",
			query: {
				token: localStorage.getItem('jwt')
			}
		});
	}

	listen(action: string, callback) {
		if (this.socket) {
			this.socket.on(action, function(data) {
				callback && callback(data);
			});
		}
	}

	send(action: string, data: any) {
		this.socket.emit(action, data);
	}

	// close(key, data) {
	// 	this.send(key, data);
	// 	this.socket.close();
	// }

	close() {
		this.socket.close();
	}
}
