import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-status-bar',
	templateUrl: './status-bar.component.html',
	styleUrls: ['./status-bar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class StatusBarComponent implements OnInit {
	@Input() statusBarInfo: any;

	constructor(
		private bsModalService: BsModalService,
		private router: Router
	) { }

	ngOnInit() { }

	onRedirect(event, status = '1') {
		event.preventDefault();

		localStorage.setItem('redirectToShipment', status);

		this.router.navigateByUrl('/operation/shipment');
	}
}
