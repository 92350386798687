import { Component, OnInit } from "@angular/core";
import { DataSharingService } from "../../../shared/services";
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-images-viewer',
	templateUrl: 'images-viewer.component.html',
	styleUrls: ['images-viewer.component.scss']
})
export class ImagesViewerComponent implements OnInit {

	showViewer: boolean = false;
	imagesList: any = [];
	subscriptionImagesViewer: Subscription;

	constructor(
		public dataSharingService: DataSharingService
	) { }

	ngOnInit() {
		this.subscriptionImagesViewer = this.dataSharingService.currentImagesViewer.subscribe(imagesViewer => {
			this.showViewer = imagesViewer['showViewer'];
			this.imagesList = imagesViewer['imagesList'];
		})
	}

	ngOnDestroy() {
		this.closeImagesViewer();
		this.subscriptionImagesViewer.unsubscribe();
	}

	closeImagesViewer() {
		this.showViewer = false;
		this.dataSharingService.emitImagesViewerEvent({
			show: false,
			imagesList: []
		});
	}

}
