import { Component, Input } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() className: string = "";
  @Input() text: string = "";
  @Input() isShow: boolean = true;
  constructor() {}
}
