import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

const GPSPrefix = '/gps-tracking/api';
const baseGPSUrl = environment.baseGPSUrl;
const baseUrl = environment.baseUrl;
const basePrefix = '/transaction/v1';

@Injectable({
    providedIn: 'root'
})

export class GPSTrackingService {

    constructor(private _http: HttpClient) {
    }

    getDriversGPSTracking(params='') {
        return this._http.get(baseGPSUrl + GPSPrefix + '/gps-drivers' + params)
            .map(res => { return res; })
            .catch(err => { return err; });
    }

    getRoutesGPSTracking(params='') {
        document.getElementById('loading').classList.add('active');
        return this._http.get(baseUrl + basePrefix + '/routes/page-gps-tracking' + params)
            .map(res => {
                document.getElementById('loading').classList.remove('active');
                return res;
            })
            .catch(err => {
                document.getElementById('loading').classList.remove('active');
                return err;
            });
    }

    getRouteDetails(id: number) {
        document.getElementById('loading').classList.add('active');
        return this._http.get(baseUrl + basePrefix + '/routes/' + id)
            .map(res => {
                document.getElementById('loading').classList.remove('active');
                return res;
            })
            .catch(err => {
                document.getElementById('loading').classList.remove('active');
                return err;
            });
    }
}
