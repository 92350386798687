import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {AppHelpers} from "../../core/helpers";
import {TranslateService} from "@ngx-translate/core";
declare const $: any;
declare const swal: any;

@Component({
	selector: 'confirmation-modal',
	templateUrl: 'confirmation.modal.html'
})

export class ConfirmationModal implements OnInit {

	modalTitle:string;
	public onClose: Subject<Object>;
	type: any;
	title: any;
	message: any;

	constructor(
		public bsModalRef: BsModalRef,
        private translate: TranslateService
	) {

	}

	ngOnInit() {
		this.modalTitle = this.title ? this.title : this.translate.instant('Confirmation');
		this.onClose = new Subject();
	}

	confirm() {
		this.onClose.next(true);
	}

}
