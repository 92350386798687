export class IUser {
    public id: number;
    public username: string;
    public firstName: string;
    public lastName: string;
    public email: string;

    public get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    constructor(json = {}) {
        Object.assign(this, json);
    }

    static decodeAPIJson(responeAPI = <any>{}): IUser {
        const model = new IUser();
        model.id = responeAPI.id;
        model.username = responeAPI.username;
        model.firstName = responeAPI.first_name;
        model.lastName = responeAPI.last_name;
        model.email = responeAPI.email;
        return new IUser(model);
    }

    static decodeAPIJsonList(responeAPI = []): IUser[] {
        return responeAPI.map(item => {
            return IUser.decodeAPIJson(item);
        });
    }

    static copyList(array: any[] = []): IUser[] {
        return array.map((el: any) => {
            return new IUser(el);
        });
    }
}