import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

@Injectable()
export class SearchService {
	constructor(
		private router: Router
	) { }

	getUrl(router) {
		let url = router.url.split('/')[1];
		if (url == 'admin') url = router.url.split('/')[2]
		return url;
	}

	setFilter(params = '') {
		let urlCurrent = sessionStorage.getItem('urlCurrent');
		if (urlCurrent === 'null' || (urlCurrent !== this.getUrl(this.router))) {
			sessionStorage.setItem('urlCurrent', this.getUrl(this.router));
			sessionStorage.removeItem("filter");
		} else {
			sessionStorage.setItem('filter', JSON.stringify(params));
		}
	}
}
