import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { Observable, throwError } from 'rxjs';

export interface IRequestOptions {
	headers?: HttpHeaders;
	observe?: 'body';
	params?: HttpParams;
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
	body?: any;
}

@Injectable()
export class HttpService {
	private api = environment.baseUrl;

	public constructor(public http: HttpClient, private router: Router) { }

	private extractData = (res: Response | any) => {
		document.getElementById('loading').classList.remove('active');
		return res;
	};

	private handleError = (err: Response | any) => {
		document.getElementById('loading').classList.remove('active');
		return throwError(err.error);
	};

	/**
	 * @param {string} endPoint
	 * @param {IRequestOptions} options
	 * @param {boolean} loading
	 * @returns {Observable<T>}
	 * @constructor
	 */
	public GetWithParams<T>(endPoint: string, params?, options?: IRequestOptions, loading: boolean = true): Observable<T> {
		if (loading) document.getElementById('loading').classList.add('active');
		return this.http.get<T>(this.api + endPoint, { params })
			.map(this.extractData)
			.catch(this.handleError);
	}

	public Get<T>(endPoint: string, options?: IRequestOptions, loading: boolean = true): Observable<T> {
		if (loading) document.getElementById('loading').classList.add('active');
		return this.http.get<T>(this.api + endPoint, options)
			.map(this.extractData)
			.catch(this.handleError);
	}

	/**
	 * @param {string} endPoint
	 * @param {Object} params
	 * @param {IRequestOptions} options
	 * @param {boolean} loading
	 * @returns {Observable<T>}
	 * @constructor
	 */
	public Post<T>(endPoint: string, params: Object, options?: IRequestOptions, loading: boolean = true): Observable<T> {
		if (loading) document.getElementById('loading').classList.add('active');
		return this.http.post<T>(this.api + endPoint, params, options)
			.map(this.extractData)
			.catch(this.handleError);
	}

	/**
	 * @param {string} endPoint
	 * @param {Object} params
	 * @param {IRequestOptions} options
	 * @param {boolean} loading
	 * @returns {Observable<T>}
	 * @constructor
	 */
	public Put<T>(endPoint: string, params: Object, options?: IRequestOptions, loading: boolean = true): Observable<T> {
		if (loading) document.getElementById('loading').classList.add('active');
		return this.http.put<T>(this.api + endPoint, params, options)
			.map(this.extractData)
			.catch(this.handleError);
	}

	/**
	 * @param {string} endPoint
	 * @param {Object} params
	 * @param {IRequestOptions} options
	 * @param {boolean} loading
	 * @returns {Observable<T>}
	 * @constructor
	 */
	public Patch<T>(endPoint: string, params?: Object, options?: IRequestOptions, loading: boolean = true): Observable<T> {
		if (loading) document.getElementById('loading').classList.add('active');
		return this.http.patch<T>(this.api + endPoint, params, options)
			.map(this.extractData)
			.catch(this.handleError);
	}

	/**
	 * @param {string} endPoint
	 * @param {IRequestOptions} options
	 * @param {boolean} loading
	 * @returns {Observable<T>}
	 * @constructor
	 */
	public Delete(endPoint: string, options?: IRequestOptions, loading: boolean = true) {
		if (loading) document.getElementById('loading').classList.add('active');
		return this.http.delete(this.api + endPoint, options)
			.map(this.extractData)
			.catch(this.handleError);
	}
}
