﻿import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AppHelpers, AppUtils } from "../../core/helpers";

const DATEPICKER_I18N_VALUES = {
	'en': {
		weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
		months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	},
	'he': {
		weekdays: ['ב', 'ג', 'ד', 'ה', 'ו', 'א', 'ש'],
		months: ['דצמ׳', 'נוב׳', 'אוק׳', 'ספט׳', 'אוג׳', 'יולי', 'יוני', 'מאי', 'אפר׳', 'מרץ', 'פבר׳', 'ינו׳']
	}
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

	currentLanguageCode: any;

	constructor(
		public appHelpers: AppHelpers
	) {
		super();
		this.currentLanguageCode = AppUtils.getCurrentLanguageCode();
	}

	getWeekdayShortName(weekday: number): string {
		return DATEPICKER_I18N_VALUES[this.currentLanguageCode].weekdays[weekday - 1];
	}

	getMonthShortName(month: number): string {
		return DATEPICKER_I18N_VALUES[this.currentLanguageCode].months[month - 1];
	}

	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}

	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}
