import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

declare const google: any;

@Directive({
    selector: '[appGooglePlaceDirective]',
    providers: [NgModel],
    // tslint:disable-next-line:use-host-property-decorator
    host: {
        '(input)': 'onInputChange()'
    }
})
export class GoogleplaceDirective {

    @Output() selectedAddress: EventEmitter<any> = new EventEmitter();
    @Output() inputChange: EventEmitter<any> = new EventEmitter();
    modelValue: any;
    autocomplete: any;
    private _el: HTMLElement;


    constructor(el: ElementRef, private model: NgModel) {
        this._el = el.nativeElement;
        this.modelValue = this.model;
        const input = this._el;

        this.autocomplete = new google.maps.places.Autocomplete(input,
            {
                componentRestrictions: { country: ['VN'] },
                types: ['geocode']  // 'establishment' / 'address' / 'geocode'
            });
        this.autocomplete.setFields(['address_components', 'geometry', 'name']);

        google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
            const place = this.autocomplete.getPlace();
            this.invokeEvent(place);

        });
    }

    invokeEvent(place: any) {
        console.log(place);
        const result = {
            address: null,
            latitude: null,
            longitude: null,
            zip_code: null,
            city: null,
            district: null,
            ward: null,
        };
        result.address = this._el['value'];
        const address_components = place['address_components'];
        if (place['geometry'] && place['geometry']['location']) {
            result.latitude = place['geometry']['location'].lat();
            result.longitude = place['geometry']['location'].lng();
        }

        address_components.forEach(item => {
            if (item['types'] && item['types'][0] === 'administrative_area_level_1') {
                result.city = item['long_name'].trim();
            }

            if (item['types'] && (item['types'][0] === 'administrative_area_level_2' || (item['types'].includes('locality') && item['types'].includes('political')))) {
                result.district = item['long_name'].trim();
            }

            if (item['types'] && item['types'][0] === 'sublocality_level_1') {
                result.ward = item['long_name'].trim();
            }

            if (item['types'] && item['types'][0] === 'postal_code') {
                result.zip_code = item['long_name'];
            }
        });
        this.selectedAddress.emit(result);
    }

    onInputChange() {
        this.inputChange.emit();
    }
}
