import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'app-location-auto-complete',
    templateUrl: 'location-auto-complete.component.html',
    styleUrls: ['location-auto-complete.component.scss']
})
export class LocationAutoCompleteComponent {

    @Input() value;
    @Input() placeholder;
    @Input() items = [];

    @Output() valueChanged = new EventEmitter();

    displayItems = [];
    hiddenSelect = true;
    indexSelected = -1;
    heightItem = 30;

    @ViewChild('autocomplete') autocompleteEl: ElementRef;

    onSeach(event) {
        this.indexSelected = -1;
        const text = event.toLowerCase();
        this.displayItems = this.items.filter(item => item.address.toLowerCase().indexOf(text) !== -1);
    }

    onSelectedItem(item, index) {
        this.value = item.address;
        this.indexSelected = index;
        this.valueChanged.emit(item);
    }

    onFocusInput() {
        if (this.value) {
            const text = this.value.toLowerCase();
            this.displayItems = this.items.filter(item => item.address.toLowerCase().indexOf(text) !== -1);
        } else {
            this.displayItems = [... this.items];
        }
        this.hiddenSelect = false;
    }

    onBlurInput() {
        setTimeout(() => {
            this.hiddenSelect = true;
        }, 200);
    }

    onMouseItem(event, index) {
        this.indexSelected = index;
        event.stopPropagation();
    }

    onHandleKeydown(event: KeyboardEvent) {

        event.stopPropagation();
        const height = this.autocompleteEl.nativeElement.offsetHeight;
        switch  (event.keyCode) {
            case 40:
                    if (this.indexSelected < (this.displayItems.length - 1)) {
                        this.indexSelected++;
                        if (((this.indexSelected + 1) * this.heightItem) > height) {
                            this.autocompleteEl.nativeElement.scrollTop += this.heightItem;
                        }
                    } else {
                        this.indexSelected = 0;
                        this.autocompleteEl.nativeElement.scrollTop = 0;
                    }
                break;
            case 38:
                    if (this.indexSelected > 0) {
                        this.indexSelected--;
                        if (this.autocompleteEl.nativeElement.scrollTop > 0) {
                            this.autocompleteEl.nativeElement.scrollTop -= this.heightItem;
                        }

                        if (this.autocompleteEl.nativeElement.scrollTop < 0) {
                            this.autocompleteEl.nativeElement.scrollTop = 0;
                        }
                    }
                break;
            case 13:
                    const item = this.displayItems[this.indexSelected];
                    this.value = item.address;
                    this.valueChanged.emit(item);
                    this.hiddenSelect = true;
                break;
            default:
          }
    }
}
