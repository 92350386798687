import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataSharingService {

	imagesViewerSource = new BehaviorSubject({});
	currentImagesViewer = this.imagesViewerSource.asObservable();

	createOrderFromHeader = new BehaviorSubject(false);
	isOrderCreatedFromHeader = this.createOrderFromHeader.asObservable();

	userProfileSource = new BehaviorSubject([]);
	currentUserProfile = this.userProfileSource.asObservable();

	mappingZoneCitySource = new BehaviorSubject(false);
	isMappingZoneCity = this.mappingZoneCitySource.asObservable();

	constructor() { }

	public emitImagesViewerEvent(imagesViewer: any) {
		this.imagesViewerSource.next(imagesViewer)
	}

	public emitOrderCreatedFromHeaderEvent(isCreatedFromHeader: any) {
		this.createOrderFromHeader.next(isCreatedFromHeader)
		setTimeout(() => {
			this.createOrderFromHeader.next(false)
		}, 1000)
	}

	public emitUserProfileEvent(userProfile: any) {
		this.userProfileSource.next(userProfile)
	}

	public emitMappingCityZoneEvent(mappingZoneCity: any) {
		this.mappingZoneCitySource.next(mappingZoneCity)
		// setTimeout(() => {
		// 	this.mappingZoneCitySource.next(false)
		// }, 1000)
	}

}
