import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppHelpers } from '../helpers/app.helpers';

@Injectable()
export class AuthGuard implements CanActivate {
	languages = [
		{ cd: 'en', label: 'English' },
		{ cd: 'he', label: 'Hebrew' },
		{ cd: 'vi', label: 'Việt Nam' },
	];

	constructor(
		private router: Router,
		private translate: TranslateService,
		public appHelpers: AppHelpers
	) {
		this.translate.addLangs(['en', 'he', 'vi']);
		let defaultLangCode = this.languages[0].cd;

		if (localStorage.getItem('lang')) {
			defaultLangCode = localStorage.getItem('lang');
			const dom: any = document.querySelector('body');
			if (defaultLangCode === 'he') {
				dom.classList.add('rtl');
			}
		} else {
			localStorage.setItem('lang', this.languages[0].cd)
		}

		this.translate.setDefaultLang(defaultLangCode);
		this.translate.use(defaultLangCode);
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const jwt = localStorage.getItem('jwt');
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		const permissions = JSON.parse(localStorage.getItem('permissions'));

		if (jwt && currentUser && permissions &&
			permissions instanceof Array && permissions.length > 0) {
			// logged in and has permissions so return true
			this.appHelpers.setUnauthorizationStatus(false);
			return true;
		}

		// not logged in so redirect to login page
		this.router.navigate(['/login']);
		return false;
	}
}
