import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { INote } from 'src/app/api-model/class/i-note';
import { AppHelpers } from 'src/app/core/helpers';

@Component({
  selector: 'app-order-note-ui',
  templateUrl: './order-note-ui.component.html',
  styleUrls: ['./order-note-ui.component.scss']
})
export class OrderNoteUiComponent implements OnInit {

  public isViewingNote: boolean;
  public addNote: INote;
  @Input() notes: INote[] = [];
  @Output() outAdd: EventEmitter<INote> = new EventEmitter();

  constructor(
    public appHelpers: AppHelpers
  ) { }

  ngOnInit() {
    this.isViewingNote = true;
    this.addNote = new INote;
  }

  onChangeViewNote() {
    this.isViewingNote = !this.isViewingNote;
  }

  onCancel() {
    this.addNote = new INote();
  }

  onAdd() {
    if (this.addNote.content) {
      const note = this.addNote;
      this.addNote = new INote();
      this.outAdd.emit(note);
    }
  }
}
