export class IStatus {
    public id:     number;
    public name:   string;
    public code:   string;
    public active: number;

    get badgeColor(): string {
        return `badge-${this.code}`;
    }

    constructor(json = {}) {
        Object.assign(this, json);
    }

    static decodeAPIJson(responeAPI = <any>{}): IStatus {
        const model = new IStatus();
        model.id = responeAPI.id;
        model.name = responeAPI.name;
        model.code = responeAPI.code;
        model.active = responeAPI.active;
        return new IStatus(model);
    }

    static decodeAPIJsonList(responeAPI = []): IStatus[] {
        return responeAPI.map(item => {
            return IStatus.decodeAPIJson(item);
        });
    }

    static copyList(array: any[] = []): IStatus[] {
        return array.map((el: any) => {
            return new IStatus(el);
        });
    }
}