import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from "./core/guards/auth.guard";
import { LoginComponent } from "./public/login/login.component";
import { RegisterComponent } from "./public/register/register.component";
import { ForgotPasswordComponent } from "./public/forgot-password/forgot-password.component";
import { SetupPasswordComponent } from "./public/setup-password/setup-password.component";
import { AccessDeniedComponent } from "./public/access-denied/access-denied.component";
import { PageNotFoundComponent } from "./public/page-not-found/page-not-found.component";

const routes: Routes = [
	{
		path: '',
		loadChildren: './feature/feature.module#FeatureModule',
		canActivate: [AuthGuard]
	},
	{ path: 'login', component: LoginComponent },
	{ path: 'register', component: RegisterComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'setup-password', component: SetupPasswordComponent },
	{ path: 'access-denied', component: AccessDeniedComponent },
	{ path: '404', component: PageNotFoundComponent },
	{ path: '**', redirectTo: '404' }
];

const config: ExtraOptions = {
	useHash: true,
	anchorScrolling: 'enabled'
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
