import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare let $: any;

@Component({
	selector: 'app-scroll-top',
	templateUrl: './scroll-top.component.html',
	styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent implements OnInit {
	pageLayoutScrolled: boolean;
	pageLayout: any;

	constructor(
		public router: Router
	) { }

	ngOnInit() { }

	ngAfterViewInit() {
		this.trackScrolling();

		this.router.events.subscribe(val => {
			if (val instanceof NavigationEnd) {
				setTimeout(() => {
					this.trackScrolling();
				}, 100)
			}
		});
	}

	trackScrolling() {
		this.pageLayout = document.querySelector('.page-layout');
		$(this.pageLayout).on('scroll', () => {
			let currentScroll = this.pageLayout.scrollTop;

			if (currentScroll > 10) {
				this.pageLayoutScrolled = true;
			} else if (this.pageLayoutScrolled && currentScroll < 10) {
				this.pageLayoutScrolled = false;
			}
		})
	}

	scrollToTop() {
		let that = this;
		(function smoothscroll() {
			// let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			let currentScroll = that.pageLayout.scrollTop;
			let slowRatio = 1;

			if (currentScroll > 0 && currentScroll <= 500) {
				slowRatio = 2;
			} else if (currentScroll > 500 && currentScroll <= 1000) {
				slowRatio = 3;
			} else if (currentScroll > 1000 && currentScroll <= 2000) {
				slowRatio = 4;
			} else {
				slowRatio = 5;
			}

			if (currentScroll > 0) {
				window.requestAnimationFrame(smoothscroll);
				that.pageLayout.scrollTo(0, currentScroll - (currentScroll / slowRatio));
			}
		})();

		// const smoothscroll = ((): any => {
		// 	let currentScroll = this.pageLayout.scrollTop;
		//
		//     if (currentScroll > 0) {
		//         window.requestAnimationFrame(smoothscroll);
		//         this.pageLayout.scrollTo(0, currentScroll - (currentScroll / 5));
		//     }
		// })();
	}
}
